<template>
  <!-- 手动操盘 -->
  <div class="container">
    <!-- PC及适配 -->
    <div class="header" v-if="isMobile == 0">
      <div class="headerLeft">
        <div class="current">
          <span class="openTitle">{{ $t("common.currentOpen") }}：</span>
          <span class="exchange">{{ exchange }}</span>
          <span class="pair" v-if="web != 'opensea'">{{
            yenUnit1.toUpperCase() + "/" + yenUnit2.toUpperCase()
          }}</span>
          <span class="pair" v-else>{{ yenUnit1.toUpperCase() }}</span>
        </div>
        <!-- 收藏 -->
        <!-- <div class="collection" @click="toCollection">
          <span v-show="isCollection">
            <img src="@/assets/images/collection_active.png" alt="" />
          </span>
          <span v-show="!isCollection">
            <img src="@/assets/images/collection.png" alt="" />
          </span>
          {{ $t("common.collection") }}
        </div> -->
        <!-- 选择盘口 -->
        <div class="g_btn btn" @click="openPopCollect">
          {{ $t("common.selectOpen") }}
        </div>
        <!-- 精度等数据展示 -->
        <!-- <div class="precisionFeeBox">
          <div>{{$t('common.priceDecimals')}}<span> 2</span></div>
          <div>{{$t('common.numDecimals')}}<span> 2</span></div>
          <div>{{$t('common.makerFee')}}<span> 0.02%</span></div>
          <div>{{$t('common.takerFee')}}<span> 0.02%</span></div>
          <div class="g_btn editBtn" @click="openPrecisionFeeDialog">
            <img src="@/assets/images/edit.png" alt="">
            {{$t('common.edit')}}
          </div>
        </div> -->
        <!-- 收藏 -->
        <div class="collection" @click="toCollection">
          <span v-show="isCollection">
            <img src="@/assets/images/collection_active.png" alt="" />
          </span>
          <span v-show="!isCollection">
            <img src="@/assets/images/collection.png" alt="" />
          </span>
          {{ $t("common.collection") }}
        </div>
        <!-- 报警 -->
        <div class="alarm">
          <img @click="openAlarm" src="@/assets/images/alarm.png" alt="" />
          <!-- 未启用 -->
          <div class="tooltip" v-if="alarmState == 0">
            {{ $t("tip.alarmAdvantage") }}
            <div class="g_btn btn2" @click="openAlarm">
              {{ $t("common.toEnable") }}
            </div>
          </div>
          <!-- 已启用 -->
          <div class="tooltip" v-else>
            {{ $t("tip.alarmIsEnable") }}
            <div class="g_btn btn3" @click="openAlarm">
              {{ $t("common.toSeeBtn") }}
            </div>
          </div>
        </div>
      </div>
      <div class="g_btn plain" @click="toggleKlineOrOpensea">
        <img src="@/assets/images/kline.png" alt="" />
        <template v-if="!showKlineOrOpensea">
          {{ web != "opensea" ? $t("stocks.openKline") : "NFT" }}
        </template>
        <template v-else>
          {{ web != "opensea" ? $t("stocks.closeKline") : "NFT" }}
        </template>
      </div>
    </div>
    <!-- 移动端判定 -->
    <template v-else>
      <div class="pankouOrCollect_phone">
        <!-- 选择盘口 -->
        <div class="head_phone" @click="openPopCollect">
          <div class="exchange">
            <span>{{ exchange }}</span>
            <span v-if="web != 'opensea'">{{
              yenUnit1.toUpperCase() + "/" + yenUnit2.toUpperCase()
            }}</span>
            <span v-else>{{ yenUnit1.toUpperCase() }}</span>
          </div>
          <div class="g_btn btn">
            <i class="iconfont2 icon-right_arrow"></i>
          </div>
        </div>
        <!-- 收藏 -->
        <div class="g_btn collection" @click="toCollection">
          <span v-show="isCollection">
            <img src="@/assets/images/collection_active.png" alt="" />
          </span>
          <span v-show="!isCollection">
            <img src="@/assets/images/collection.png" alt="" />
          </span>
        </div>
      </div>
      <!-- 报警 -->
      <div class="g_btn alarm_phone" @click="openAlarm">
        <div class="alarmLabel">
          <img src="@/assets/images/alarm.png" alt="" />
          {{
            alarmState == 0
              ? $t("alarmSystem.alarmSystem")
              : $t("tip.alarmIsEnable")
          }}
        </div>
        <div class="g_btn btn">
          {{ alarmState == 0 ? $t("common.toEnable") : $t("common.toSeeBtn") }}
          <i class="iconfont2 icon-right_arrow"></i>
        </div>
      </div>
      
      <!-- <div class="precisionFeeBox">
        <div>{{$t('common.priceDecimals')}}<span> 2</span></div>
        <div>{{$t('common.numDecimals')}}<span> 2</span></div>
        <div>{{$t('common.makerFee')}}<span> 0.02%</span></div>
        <div>{{$t('common.takerFee')}}<span> 0.02%</span></div>
      </div> -->
    </template>

    <div class="content" :class="{ content_phone: isMobile != 0 }">
      <div class="contentLeft">
        <div
          class="row"
          :class="{
            notOrderBigScreenRow: device2 != 'mobile' && (web_type != 0&&web_type != 3),
          }"
        >
          <!-- 手动下单、量化工具 -->
          <div class="toolCon">
            <toolBox
              ref="toolBox"
              :web="web"
              :web_type="web_type"
              :yenUnit1="yenUnit1"
              :yenUnit2="yenUnit2"
              @updateData="updateData"
              @openPopAddToken="openPopAddToken"
              :addTokenWeb.sync="addTokenWeb"
              :addTokenExchange.sync="addTokenExchange"
              :balance1="balance1"
              :balance2="balance2"
              :subBalance="subBalance"
              :subBalance2="subBalance2"
              :bidsList="bidsList"
              :asksList="asksList"
              @openSubTransfer="openSubTransfer"
              :walletGroupList="walletGroupList"
              :orderList="orderList"
            ></toolBox>
          </div>
          <!-- 盘口-小屏 -->
          <div v-if="device2 == 'mobile'" class="pankou">
            <pankouBox
              ref="pankouBox"
              :web="web"
              :web_type="web_type"
              :bidsList="bidsList"
              :asksList="asksList"
              :orderList="orderList"
              @selectData="selectData"
            ></pankouBox>
          </div>
          <div class="logOrRobotBox">
            <!-- 机器人列表 -->
            <div class="robotCon">
              <robotBox
                ref="robotBox"
                :web="web"
                :web_type="web_type"
                :yenUnit1="yenUnit1"
                :yenUnit2="yenUnit2"
                :closingRobotList="closingRobotList"
                @updateClosingRobot="updateClosingRobot"
                @openTip="openTip"
                @openPop="openPop"
                @openSubTransfer="openSubTransfer"
                @getRobotList="getRobotList"
                :runRobots="runRobots"
                @openAlarmTip="openAlarmTip"
              >
              </robotBox>
            </div>
            <!-- 机器人状态日志 -->
            <div class="logCon">
              <div class="boxTitle">
                <img src="@/assets/images/pankouLogIcon.png" alt="">
                {{$t('stocks.orderBookMarketData')}}
              </div>
              <ul class="g_tabTit tabTit">
                <li
                  :class="{ active: selectRuntimeLogType == 'current' }"
                  @click="switchRuntimeLogType('current')"
                >
                  {{ $t("stocks.current") }}
                </li>
                <li
                  :class="{ active: selectRuntimeLogType == 'all' }"
                  @click="switchRuntimeLogType('all')"
                >
                  {{ $t("stocks.all") }}
                </li>
              </ul>
              <div class="switch">
                <el-checkbox
                  v-model="runtimeLogIsLoop"
                  true-label="loop"
                  false-label="notloop"
                  @change="changeLoop"
                  >{{ $t("stocks.update") }}</el-checkbox
                >
              </div>
              <div class="tabCon">
                <template v-if="runtimeLogList.length">
                  <ul
                    v-infinite-scroll="loadRuntimeLog"
                    :infinite-scroll-delay="400"
                    :infinite-scroll-disabled="
                      runtimeLogLoading || load_disabled2
                    "
                  >
                    <li
                      class="item"
                      :class="{
                        errItem: item.color == 'red',
                        noticeItem: item.color == 'yellow',
                      }"
                      v-for="(item, index) in runtimeLogList"
                      :key="index"
                    >
                      <div class="pairTime">
                        <span>{{ item.web + " " + item.currency }}</span>
                        <span>{{ item.time }}</span>
                      </div>
                      <div
                        :class="{
                          errRow: item.color == 'red',
                          noticeRow: item.color == 'yellow',
                        }"
                        v-if="item.color == 'red' || item.color == 'yellow'"
                      >
                        <span v-if="item.color == 'red'">
                          <img src="@/assets/images/warning.png" alt="" />
                          {{ $t("common.warning") }}!
                        </span>
                        <span v-else>
                          <img src="@/assets/images/notice.png" alt="" />
                          {{ $t("common.notice") }}!
                        </span>
                        <!-- 联系客服 -->
                        <!-- <span
                          class="g_btn"
                          v-if="item.ps == 'concat'"
                          @click="contact"
                        >
                          {{ $t("renew.contact") }}
                        </span> -->
                        <!-- 跳转钱包管理 -->
                        <span
                          class="g_btn"
                          v-if="
                            item.ps == 'wallet' && item.web_info.type == 1
                          "
                          @click="jumpWallet(item)"
                        >
                          {{ $t("common.settings") }}
                        </span>
                        <!-- 设置机器人/进入盘口 -->
                        <template
                          v-else-if="item.ps == 'wallet' || item.ps == 'setting'"
                        >
                          <span
                            class="g_btn"
                            v-if="selectRuntimeLogType == 'current'"
                            @click="settings(item)"
                            >{{ $t("common.settings") }}</span
                          >

                          <span
                            class="g_btn"
                            v-if="selectRuntimeLogType == 'all'"
                            @click="handicap(item)"
                            >{{ $t("common.handicap") }}</span
                          >
                        </template>
                      </div>
                      <div class="sucRow" v-else>
                        <span>
                          <img src="@/assets/images/success.png" alt="" />
                          {{ $t("common.success") }}!
                        </span>
                      </div>

                      <div class="content">{{ item.content }}</div>
                    </li>
                  </ul>
                  <p v-if="runtimeLogLoading">{{ $t("common.loding") }}...</p>
                  <p v-if="load_disabled2">{{ $t("common.noMore") }}~</p>
                </template>
                <el-empty
                  v-else
                  :image="emptyImg"
                  :image-size="120"
                  :description="$t('common.noData')"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- 订单列表 cex -->
        <div class="orderCon" v-if="web_type == 0||web_type == 1||web_type == 3">
          <div class="head">
            <ul class="g_tabTit tabTit">
              <li :class="{ active: tabIndex == 1 }" @click="switchTab(1)">
                <div>
                  {{ $t("stocks.orderManagement") + "(" + orderList.length + ")" }}
                </div>
              </li>
              <li v-if="web_type==3" :class="{ active: tabIndex == 2 }" @click="switchTab(2)">
                <div>
                  {{ $t("stocks.currentPosition") + "(" + positionList.length + ")" }}
                </div>
              </li>
            </ul>
            <!-- 交易记录入口 -->
            <div class="right" @click="jump('/record')" v-if="web_type == 0||web_type == 1">
              <i class="iconfont2 icon-jilu"></i>
              <span>{{ $t("common.transactionRecord") }}</span>
            </div>
            <!-- 头寸管理和用户风险管理入口 -->
            <!-- <div class="rightBox" v-if="web_type==3">
              <div class="g_btn" @click="jump('/positionManagement')">
                {{ $t("stocks.positionManagement") }}
              </div>
              <div class="g_btn" @click="jump('/userRiskManagement')">
                {{ $t("stocks.userRiskManagement") }}
              </div>
            </div> -->
          </div>
          <div class="tableBox">

          <!-- 订单管理 -->
          <el-table
            stripe
            height="300px"
            v-if="tabIndex == 1"
            :data="orderList">
            <el-table-column
              header-align="center"
              align="center"
              prop="price"
              :label="$t('common.pirce')"
              min-width="120">
            </el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              prop="amount"
              :label="$t('common.num')"
              min-width="140">
            </el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              prop="deal_amount"
              :label="$t('common.closed')"
              min-width="120">
            </el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              prop="side"
              :label="$t('common.direction')"
              min-width="80">
              <template slot-scope="scope">
                <span v-if="scope.row.side == 'buy'&&web_type==0" class="green">{{
                  $t("common.purchase")
                }}</span>
                <span v-else-if="scope.row.side == 'sell'&&web_type==0" class="red">{{ $t("common.sellout") }}</span>
                <span v-else-if="scope.row.direction=='long'&&web_type==3" class="green">{{ $t("stocks.goLong") }}</span>
                <span v-else-if="scope.row.direction=='short'&&web_type==3" class="red">{{ $t("stocks.goShort") }}</span>
              </template>
            </el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              prop="time"
              :label="$t('common.time')"
              min-width="160">
            </el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              :label=" $t('common.cancelOrder')"
              min-width="80">
              <template slot-scope="scope">
                <div class="g_btn btn" @click="handleCancel(scope.row)">
                  <span>{{ $t("common.cancelOrder") }}</span>
                </div>
              </template>
            </el-table-column>
            <el-empty slot="empty"
              :image="emptyImg"
              :image-size="120"
              :description="$t('common.noData')"
            />
          </el-table>

          <!-- 当前持仓 -->
          <el-table
            stripe
            height="300px"
            v-else-if="tabIndex == 2"
            :data="positionList">
            <el-table-column
              class-name="column"
              header-align="center"
              align="center"
              prop="direction"
              :label="$t('common.direction')"
              min-width="80">
              <template slot-scope="scope">
                <div v-if="scope.row.direction=='short'" class="red">
                  {{ $t('stocks.goShort') }}
                </div>
                <div v-else class="green">
                  {{ $t('stocks.goLong') }}
                </div>
                <div class="g_btn tag">{{scope.row.leverage}}.00x</div>
              </template>
            </el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              prop="total"
              :label="$t('stocks.positionQuantity')"
              min-width="120">
            </el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              prop="balance"
              :label="$t('stocks.liquidatableQuantity')"
              min-width="120">
            </el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              prop="total_usd"
              :label="$t('stocks.positionAmount')"
              min-width="120">
            </el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              prop="price"
              :label="$t('stocks.averagePositionPrice')"
              min-width="120">
            </el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              prop="profit"
              :label="$t('stocks.unrealizedProfit')"
              min-width="120">
              <template slot-scope="scope">
                <span :class="scope.row.profit<0?'red':'green'">
                  {{ scope.row.profit }}{{yenUnit2.toUpperCase()}}
                </span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              class-name="column"
              header-align="center"
              align="center"
              prop="margin"
              :label="$t('stocks.margin')"
              min-width="120">
              <template slot-scope="scope">
                <div>{{ scope.row.margin }}{{yenUnit2.toUpperCase()}}</div>
                <div>{{ true?$t("stocks.fullPosition"):$t("stocks.isolatedPosition") }}</div>
              </template>
            </el-table-column> -->
            <el-table-column
              :label="$t('common.operation')"
              min-width="360">
              <template slot-scope="scope">
                <el-input-number size="small" :placeholder="$t('common.pirce')" v-model="scope.row.closePrice" :controls="false" :min="0" class="inp"></el-input-number>
                <el-input-number size="small" :placeholder="$t('common.num')" v-model="scope.row.closeNum" :controls="false" :min="0" class="inp"></el-input-number>
                <div class="g_btn btn2" @click="handleClosePosition(scope.row,'limit')">
                  {{ $t("stocks.closePosition") }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              :label="$t('stocks.closeAll')"
              min-width="90">
              <template slot-scope="scope">
                <div class="g_btn btn2" @click="handleClosePosition(scope.row,'market')">
                  {{ $t("stocks.closeAll") }}
                </div>
              </template>
            </el-table-column>
            <el-empty slot="empty"
              :image="emptyImg"
              :image-size="120"
              :description="$t('common.noData')"
            />
          </el-table>
          </div>

        </div>
      </div>
      <!-- 盘口-大屏 -->
      <div class="contentRight" v-if="device2 != 'mobile'">
        <pankouBox
          ref="pankouBox"
          :web="web"
          :web_type="web_type"
          :bidsList="bidsList"
          :asksList="asksList"
          :orderList="orderList"
          @selectData="selectData"
        ></pankouBox>
      </div>
    </div>

    <!-- 交易所选择 -->
    <exchange-dialog
      :show.sync="popExchange"
      :web="dialogWeb"
      @close="closeExchange"
      @select="handleSelect"
    />
    <!-- 选择盘口 常用盘口 -->
    <el-dialog
      class="collect"
      :title="$t('common.selectOpen')"
      :visible.sync="popCollection"
    >
      <div class="manual_box">
        <div class="selectBox">
          <div class="manualTitle">{{ $t("stocks.selectExchange") }}</div>
          <div class="select" @click="openPopExchange">
            <input
              v-model="popexchange"
              type="text"
              :placeholder="$t('common.select')"
              readonly
              class="inp"
            />
            <i class="iconfont icon-down"></i>
          </div>
        </div>
        <div class="pairBox">
          <div class="manualTitle">{{ $t("stocks.enterPair") }}</div>
          <div class="pair" v-if="popweb != 'opensea'">
            <input
              v-model="popyenUnit1"
              type="text"
              :placeholder="$t('common.currency')"
              class="inp"
            />
            <i class="split"></i>
            <input
              v-model="popyenUnit2"
              type="text"
              :placeholder="$t('common.currency')"
              class="inp"
            />
          </div>
          <div class="pair opensea" v-else>
            <input
              v-model="popyenUnit1"
              type="text"
              :placeholder="$t('common.opensea')"
              class="inp"
            />
          </div>
        </div>
      </div>
      <div class="foot">
        <span class="g_btn btn" @click="popCollection = false">{{
          $t("common.back")
        }}</span>
        <span class="g_btn btn submit" @click="handleInConfirm">{{
          $t("common.confirm")
        }}</span>
      </div>
      <div class="title">{{ $t("common.commonly") }}</div>
      <div class="company_box">
        <div class="tab_title">
          <div class="left">{{ $t("common.exchange") }}</div>
          <div class="right">{{ $t("common.pair") }}</div>
        </div>
        <div
          class="item"
          v-for="(item, index) in collectList"
          :key="index"
          @click="commonlyTc(item)"
        >
          <div class="left">{{ item.exchange_name }}</div>
          <div class="right">
            <div>{{ item.currency }}</div>
            <div
              class="collect_btn"
              @click.stop="collectCancel(item.currency, item.web_id, item.web)"
            >
              <img src="@/assets/images/collection_active.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 机器人说明弹窗 -->
    <el-dialog
      class="w900"
      :title="
        robotType
          ? $t('robot.list.' + robotType + '.name') + $t('common.explain')
          : ''
      "
      :visible.sync="showTips"
    >
      <div
        class="tips_form"
        v-html="robotType ? $t('robot.list.' + robotType + '.html') : ''"
      ></div>
    </el-dialog>

    <!-- 机器人关闭倒计时 -->
    <template v-if="closingRobotList.length > 0">
      <div
        class="notification"
        :class="{ active: closingRobot[item] > 0 }"
        v-for="(item, index) in closingRobotList"
        :key="index"
        :style="{ top: index == 0 ? 70 + 'px' : 70 + index * 90 + 'px' }"
      >
        <h2 class="notification__title">
          {{ $t("robot.list." + item + ".name") }}
        </h2>
        <div class="notification__content">
          <p>
            {{ $t("tip.closing", { time: closingRobot[item] }) }}
          </p>
        </div>
      </div>
    </template>

    <!-- 分拨资金弹窗 -->
    <subTransfer
      ref="subTransfer"
      :address_list="address_list"
      :id_list="id_list"
      :chain="chain"
      :token="token"
      :approve_token="approve_token"
      :web="transferWeb"
      :exchange="transferExchange"
    ></subTransfer>

    <KlineOrOpensea
      ref="KlineOrOpensea"
      v-if="showKlineOrOpensea"
      :web="web"
      :yenUnit1="yenUnit1"
      :yenUnit2="yenUnit2"
      :web_type="web_type"
    ></KlineOrOpensea>

    <!-- 联系我们弹窗 -->
    <el-dialog
      :title="$t('common.contact')"
      :visible.sync="showContact"
    >
      <ul class="contaceList">
        <li
          v-for="(item, index) in contactList"
          :key="index"
          @click="jumpOut(item.wechat)"
        >
          <i class="iconfont icon-telegram"></i>
          <span>{{ item.nickname }}</span>
        </li>
      </ul>
    </el-dialog>

    <!-- 报警弹窗 -->
    <alarmDialog
      ref="alarmDialog"
      :web="web"
      :yenUnit1="yenUnit1"
      :yenUnit2="yenUnit2"
      @updateState="updateAlarmState"
    ></alarmDialog>
  </div>
</template>

<style scoped="scoped" lang="stylus">
@import 'index.styl';
</style>

<script>
import { mapGetters } from "vuex";
import Decimal from "decimal.js";
import robotNameFormat from "@/utils/methApi/robotNameFormat";

import toolBox from "@/components/stocks/toolBox.vue";
import pankouBox from "@/components/stocks/pankouBox.vue";
import robotBox from "@/components/stocks/robotBox.vue";
import KlineOrOpensea from "@/components/stocks/KlineOrOpensea";

import ExchangeDialog from "@/components/exchange.vue";
import subTransfer from "@/components/subTransfer/subTransfer.vue";
import alarmDialog from "@/components/alarm/alarmDialog.vue";
import {
  getTradingPairInfo,
  setTradingPairInfo,
} from "@/utils/methApi/tradingPairDefault";

// api
import {
  getCurrencyBalance,
  getRuntimeLog,
  getPankou,
  getOrdersList,
  getPositionOrders,
  tradeCancel,
  collect,
  collectCancel,
  getRobotList,
  getGroups,

  tradeCoin,
} from "@/api/markets";
import { getPriceSequenceList } from "@/api/robot";

let banlanceTimeR, runtimeLogTimeR, pankouTimeR, orderTimeR, orderTimeR2;

export default {
  name: "stocks",
  components: {
    toolBox,
    pankouBox,
    robotBox,

    ExchangeDialog,
    subTransfer,
    KlineOrOpensea,

    alarmDialog,
  },
  data() {
    return {
      isFromAccount: false, // 是否来自于账号列表的跳转，且继续后续交易对判定
      isDestroyed: false, //页面是否处于销毁阶段
      // 常用盘口
      popCollection: false,
      isCollection: false,
      popexchange: getTradingPairInfo("exchange"),
      popweb: getTradingPairInfo("web"),
      popyenUnit1: getTradingPairInfo("yenUnit1"),
      popyenUnit2: getTradingPairInfo("yenUnit2"),
      popweb_id: getTradingPairInfo("web_id"),
      popweb_type: getTradingPairInfo("web_type"), //交易所类型 0-cex、1-dex

      emptyImg: require("@/assets/images/noorder.png"),

      // 交易所选择
      popExchange: false,
      exchange: getTradingPairInfo("exchange"),
      web: getTradingPairInfo("web"),
      yenUnit1: getTradingPairInfo("yenUnit1"),
      yenUnit2: getTradingPairInfo("yenUnit2"),
      web_id: getTradingPairInfo("web_id"),
      web_type: getTradingPairInfo("web_type"), //交易所类型 0-cex、1-dex

      // 钱包数据
      balance1: {},
      balance2: {},
      // 交易钱包
      subBalance: {},
      subBalance2: {},

      // 添加token合约 量化工具
      isAddToken: false,
      addTokenExchange: "",
      addTokenWeb: "",

      // 机器人状态日志
      runtimeLogList: [],
      runtimeLogPage: 1,
      selectRuntimeLogType: "current",
      runtimeLogIsLoop: localStorage.getItem("light_is_loop") || "loop", //是否自动更新
      runtimeLogLoading: false,
      load_disabled2: false,//机器人日志

      // 订单/持仓 tab切换
      tabIndex: 1,
      // 订单列表
      orderList: [], 
      orderPage: 1, //订单页码-无效字段实际就一页
      //持仓列表
      positionList: [],

      // 盘口
      bidsList: [{ price: 0, amount: 0 }],
      asksList: [{ price: 0, amount: 0 }],

      // 机器人管理
      robotList: [],
      robotType: "",
      showTips: false,
      closingRobot: {}, //存放正在关闭倒数的机器人，及倒计时{robotType:timedown}
      closingRobotList: [], //存放正在关闭倒数的机器人[robotType]
      priceSequenceList: [], //序列方案列表
      walletGroupList: [], //钱包分组列表

      // 子组件的交易所
      sonPopRobotType: "",
      dialogWeb: null,
      sonFormNum: 0,

      // 分拨资金
      address_list: [],
      id_list: [],
      chain: "",
      token: "",
      approve_token: "",
      transferWeb: "",
      transferExchange: "",

      // average: '',// 盘口买入卖出中间值

      // 启动的机器人
      runRobots: [],

      // 联系我们
      showContact: false,
      contactList: [],

      // 报警状态
      alarmState: 0,
    };
  },
  beforeCreate() {
    this.$store.commit("markets/SET_SHOW_KLINE_OR_OPENSEA", false);
  },
  created() {
    this.$store.commit(
      "markets/SET_POSITION_X",
      window.innerWidth - Math.min(window.innerWidth, 978)
    );
    this.$store.commit("markets/SET_POSITION_Y", 0);

    // 账号列表跳转
    if (
      localStorage.getItem("hasBind") == 1 &&
      (!localStorage.getItem("hasEnter") ||
        localStorage.getItem("hasEnter") == 0)
    ) {
      if (localStorage.getItem("oldWebId") == getTradingPairInfo("web_id")) {
        // 如果原本地web与新绑定交易所相同，则修改交易对且不弹窗
        localStorage.setItem("hasEnter", 1);
      } else if (
        localStorage.getItem("oldWebId") != "5" &&
        this.yenUnit1.toUpperCase() != "BTC" &&
        this.yenUnit2.toUpperCase() != "USDT"
      ) {
        //币安Binance-5
        // 如果本地有其他交易所数据(不为默认值)，则展示本地交易对并弹窗等待确认
        // 打开盘口选择弹窗
        this.popCollection = true;
      } else {
        // 打开盘口选择弹窗
        this.popCollection = true;
        this.isFromAccount = true; //执行收藏列表判定
      }
    }

    let params = this.$route.params;
    if (params.web) {
      this.popexchange = params.exchange;
      this.popweb = params.web;
      this.popweb_id = params.web_id;
      this.popweb_type = params.web_type;

      this.exchange = params.exchange;
      this.web = params.web;
      this.web_id = params.web_id;
      this.web_type = params.web_type;

      // 操盘机器人列表进入盘口
      this.popyenUnit1 = params.yenUnit1.toUpperCase();
      this.popyenUnit2 = params.yenUnit2.toUpperCase();

      this.yenUnit1 = params.yenUnit1.toUpperCase();
      this.yenUnit2 = params.yenUnit2.toUpperCase();
    }

    // 如果数据都存在则进入盘口
    if (this.web != "opensea") {
      if (!!this.web && !!this.yenUnit1 && !!this.yenUnit2) {
        this.handleIn();
      }
    } else {
      // opensea
      if (!!this.web && !!this.yenUnit1) {
        this.handleIn();
      }
    }

    // 获取收藏列表
    this.getCollectList().then(() => {
      if (this.isFromAccount) {
        // 判断当前盘口是否与收藏列表中的web有相同项，则展示本地交易对并弹窗等待确认
        if (!!this.collectList && this.collectList.length > 0) {
          let notHas = true;
          try {
            this.collectList.forEach((item) => {
              if (item.web_id == this.web_id) {
                var currencyArr = item.currency.toUpperCase().split("_");
                this.popyenUnit1 = currencyArr[0];
                this.popyenUnit2 = currencyArr[1];
                // 打开盘口选择弹窗
                this.popCollection = true;
                throw Error();
              }
            });
          } catch (error) {
            notHas = false;
          }
          if (notHas) {
            // 获取账户余额，如果存在资产，则显示最高币+USDT
          }
        }
      }
    });
  },
  computed: {
    ...mapGetters([
      "account",
      "type",
      "device",
      "device2",
      "collectList",
      "isMobile",
      "lang",
      "showKlineOrOpensea",
      "telegram",
      "cexRobotList1",
      "cexRobotList2",
      "cexRobotList4",
    ]),
  },
  methods: {
    // 打开编辑精度费率弹窗
    openPrecisionFeeDialog(){},

    // 打开报警确认弹窗
    openAlarmTip() {
      this.$refs.alarmDialog?.openCheckTip();
    },
    // 打开报警弹窗
    openAlarm() {
      // 判断是否绑定电报，若没有则弹确认窗前往绑定电报
      if (!this.telegram) {
        this.$confirm(this.$t("tip.noTeleram"), this.$t("tip.tips"), {
          confirmButtonText: this.$t("common.yes"),
          center: true,
          // showClose: false,
          customClass: "deleteOk",
          cancelButtonText: this.$t("common.no"),
        }).then((res) => {
          window.location.href = "https://t.me/lightning_mm_bot";
        });
      } else {
        this.$refs.alarmDialog?.openAlarm()
      }
    },
    // 更新报警状态
    updateAlarmState(state) {
      this.alarmState = state;
    },

    // 打开K线、NFT漂浮窗
    toggleKlineOrOpensea() {
      this.$store.commit(
        "markets/SET_SHOW_KLINE_OR_OPENSEA",
        !this.showKlineOrOpensea
      );
    },

    // 获取新数据 重置轮询 资金、盘口、订单
    updateData() {
      this.getBalance();
      this.getPankouAll();
      // cex更新订单
      this.getOrderData();
      // 合约更新持仓
      this.getOrderData2();
    },
    // 打开分拨授权
    openSubTransfer({
      address_list,
      id_list,
      chain,
      token,
      approve_token,
      transferWeb,
      transferExchange,
    }) {
      this.address_list = address_list;
      this.id_list = id_list;
      this.chain = chain;
      this.token = gas_token;
      this.approve_token = approve_token;
      this.transferWeb = web;
      this.transferExchange = exchange;
      this.$refs.subTransfer.open();
    },

    // 跳转钱包管理
    jumpWallet(item) {
      this.$router.push({
        path: "/subManage",
        query: {
          web: item.web,
          yenUnit1: item.currency.split("_")[0],
          yenUnit2: item.currency.split("_")[1],
        },
      });
    },
    // 前往设置 日志点击时打开对应机器人tab并将滚动条滚动至对应机器人
    settings(item) {
      if (item.type == "hupan") {
        var robotType = "hupan_buy";
      } else {
        var robotType = item.type;
      }

      if (item.web_info.type == 0) {
        //CEX
        var isCexRobotList1 = this.cexRobotList1.some(
          (obj) => obj.robotType === robotType
        );
        if (isCexRobotList1) {
          this.$refs.robotBox?.selectTab(0);

          this.$nextTick(() => {
            this.$refs.robotBox?.ScrollToRobot(robotType);
          });
          return;
        }
        var isCexRobotList2 = this.cexRobotList2.some(
          (obj) => obj.robotType === robotType
        );
        if (isCexRobotList2) {
          this.$refs.robotBox?.selectTab(1);

          this.$nextTick(() => {
            this.$refs.robotBox?.ScrollToRobot(robotType);
          });
          return;
        }
        var isCexRobotList4 = this.cexRobotList4.some(
          (obj) => obj.robotType === robotType
        );
        if (isCexRobotList4) {
          this.$refs.robotBox?.selectTab(2);

          this.$nextTick(() => {
            this.$refs.robotBox?.ScrollToRobot(robotType);
          });
          return;
        }
      } else {
        //DEX
        this.$nextTick(() => {
          this.$refs.robotBox?.ScrollToRobot(robotType);
        });
      }
    },
    // 获取当前盘口机器人列表
    async getRobotList(needToNull) {
      // 如果是dex则获取钱包分组列表
      if (this.web_type == 1) {
        try {
          this.walletGroupList = await getGroups({
            account: this.account,
            web: this.web,
          });
          this.$nextTick(() => {
            this.$refs.robotBox?.setWalletGroupList(this.walletGroupList || []);
          });
        } catch (error) {
          console.log("获取钱包分组列表失败");
        }
      }

      if (this.web != "opensea") {
        var currency = this.yenUnit1 + "_" + this.yenUnit2;
      } else {
        var currency = this.yenUnit1;
      }
      // 获取序列方案列表
      try {
        this.priceSequenceList = await getPriceSequenceList({
          account: this.account,
          web: this.web,
          currency: this.yenUnit1 + "_" + this.yenUnit2,
        });
        this.$nextTick(() => {
          this.$refs.robotBox?.setPriceSequenceList(
            this.priceSequenceList || []
          );
        });
      } catch (error) {
        console.log("获取序列方案列表失败");
      }

      let res = await getRobotList({
        account: this.account,
        state: "0",
        web: this.web,
        currency,
      });
      if (needToNull != "not") {
        this.$nextTick(() => {
          // 清空机器人原数据
          this.$refs.robotBox?.robotDataToNull();
        });
      }
      if (!!res) {
        this.runRobots = [];
        res.forEach((item) => {
          console.log(item)
          item.robotType = robotNameFormat(item.type, item.sub_type);
          if (item.state == 1) {
            this.runRobots.push(item.robotType);
          }

          // param数据初始化
          this.$nextTick(() => {
            this.$refs.robotBox?.paramInit(item);
          });
        });
      }
      this.robotList = res;
    },
    // 打开机器人提示弹窗
    openTip(robotType) {
      this.robotType = robotType;
      this.showTips = true;
    },
    // 停止机器人时记录closingRobot
    updateClosingRobot(robotType) {
      // 如果停止成功 则提示10S倒计时自动关闭，并加入closingRobot
      this.closingRobotList.push(robotType);
      this.$set(this.closingRobot, robotType, 10);
      var robotTimeR = setInterval(() => {
        if (this.closingRobot[robotType] > 0) {
          var time = this.closingRobot[robotType] - 1;
          this.$set(this.closingRobot, robotType, time);
        } else {
          this.closingRobotList.splice(
            this.closingRobotList.indexOf(robotType),
            1
          );
          clearInterval(robotTimeR);
        }
      }, 1000);
      // 页面销毁前清除定时器
      this.$once("hook:beforeDestroy", function () {
        clearInterval(robotTimeR);
      });
    },
    // 获取盘口数据-均价
    // getAverage(web, currency) {
    // 	this.average = 0//初始化

    // 	var sellPrice = !this.bidsList || !this.bidsList.length ? 0 : this.bidsList[0].price;
    // 	var buyPrice = !this.asksList || !this.asksList.length ? 0 : this.asksList[this.asksList.length - 1].price;
    // 	this.average = (new Decimal(sellPrice).add(new Decimal(buyPrice))).div(new Decimal(2)).toString()
    // },

    // 打开选择盘口
    openPopCollect() {
      this.popexchange = this.exchange;
      this.popweb = this.web;
      this.popyenUnit1 = this.yenUnit1;
      this.popyenUnit2 = this.yenUnit2;
      this.popweb_id = this.web_id;
      this.popCollection = true;
    },
    // 常用填充
    commonlyTc(item) {
      this.popweb = item.web;
      this.popyenUnit1 = item.currency.split("_")[0];
      this.popyenUnit2 = item.currency.split("_")[1];
      this.popexchange = item.exchange_name;
      this.popweb_id = item.web_id;
      this.popweb_type = item.web_type;
      this.handleInConfirm();
    },
    // 日志进入错误盘口
    handicap(item) {
      this.exchange = item.web_info.name;
      this.web = item.web_info.web;
      var yenUnitArr = item.currency.split("_");
      this.yenUnit1 = yenUnitArr[0].toUpperCase();
      this.yenUnit2 =
        item.web_info.web != "opensea" ? yenUnitArr[1].toUpperCase() : "";
      this.web_id = item.web_info.id;
      this.web_type = item.web_info.type;
      // 是否进入过盘口,handleIn在created会被调用，不能放handleIn中
      localStorage.setItem("hasEnter", 1);

      this.handleIn();
    },
    // 确认进入盘口
    async handleInConfirm() {
      // 非空验证
      if (!this.popweb) {
        this.$message({
          message: this.$t("tip.selectExchange") + "！",
          type: "error",
          center: true,
          customClass: "shotMsg",
        });
      } else if (
        this.popweb != "opensea" &&
        (!this.popyenUnit1 || !this.popyenUnit2)
      ) {
        this.$message({
          message: this.$t("tip.enterPair") + "！",
          type: "error",
          center: true,
          customClass: "shotMsg",
        });
      } else if (this.popweb == "opensea" && !this.popyenUnit1) {
        // opensea
        this.$message({
          message: this.$t("tip.enterCollect") + "！",
          type: "error",
          center: true,
          customClass: "shotMsg",
        });
      } else {
        this.exchange = this.popexchange;
        this.web = this.popweb;
        this.yenUnit1 = this.popyenUnit1.toUpperCase().trim();
        this.yenUnit2 =
          this.popweb != "opensea" ? this.popyenUnit2.toUpperCase().trim() : "";
        this.web_id = this.popweb_id;
        this.web_type = this.popweb_type;
        // 是否进入过盘口,handleIn在created会被调用，不能放handleIn中
        localStorage.setItem("hasEnter", 1);

        // 判定是否收藏，如果点击进入盘口，且没被收藏，则收藏盘口
        try {
          await this.judgmentColl("toColl");
        } catch (error) {}
        this.popCollection = false;

        this.handleIn();
      }
    },
    // 进入盘口
    handleIn() {
      // 缓存交易所、交易对
      setTradingPairInfo({
        exchange: this.exchange,
        web: this.web,
        yenUnit1: this.yenUnit1,
        yenUnit2: this.yenUnit2,
        web_id: this.web_id,
        web_type: this.web_type,
      });

      this.tabIndex = 1;
      // 获取 账户余额、普通盘口、订单列表 并重置轮询
      this.updateData();
      // 获取 机器人状态日志 并重置轮询
      this.getRuntimeLog();
      // 获取 当前盘口机器人管理列表
      this.getRobotList();

      this.$nextTick(() => {
        // 工具模块 init
        this.addTokenExchange = "";
        this.addTokenWeb = "";
        this.$refs.toolBox?.init();
        // 获取报警系统状态
        this.$refs.alarmDialog?.getAlertState();

        // K线opensea模块 init
        this.$refs.KlineOrOpensea?.init();
      });
    },

    // 获取收藏列表
    async getCollectList() {
      await this.$store.dispatch("markets/getCollectList");
      this.judgmentColl();
      return "getCollectList ok";
    },
    // 收藏盘口切换 收藏/取消收藏
    async toCollection() {
      if (this.web != "opensea") {
        var currency = this.yenUnit1 + "_" + this.yenUnit2;
      } else {
        var currency = this.yenUnit1;
      }
      if (!this.isCollection) {
        try {
          var res = await collect({
            account: this.account,
            currency,
            web_id: this.web_id,
            web: this.web,
          });
          this.$message({
            message: this.$t("tip.collectionSuc"),
            type: "success",
            center: true,
            customClass: "shotMsg",
          });
          this.isCollection = !this.isCollection;
          this.getCollectList();

          return res;
        } catch (error) {
          if ((error.code = 2065)) return "reverse";
        }
      } else {
        collectCancel({
          account: this.account,
          currency,
          web_id: this.web_id,
          web: this.web,
        }).then((res) => {
          this.$message({
            message: this.$t("tip.cancelCollectionSuc"),
            type: "success",
            center: true,
            customClass: "shotMsg",
          });
          this.isCollection = !this.isCollection;
          this.getCollectList();
        });
      }
    },
    // 判定是否收藏
    async judgmentColl(toColl) {
      var isCollection = false;
      // 判断当前盘口是否已收藏
      if (!!this.collectList && this.collectList.length > 0) {
        if (this.web != "opensea") {
          var currencyNow = (this.yenUnit1 + "_" + this.yenUnit2).toUpperCase();
        } else {
          var currencyNow = this.yenUnit1.toUpperCase();
        }
        this.collectList.forEach((item) => {
          var currency = item.currency.toUpperCase();
          if (item.web_id == this.web_id && currencyNow == currency) {
            isCollection = true;
          }
        });
      } else {
        isCollection = false;
      }
      this.isCollection = isCollection;
      // 如果点击进入盘口，且没被收藏，则收藏盘口
      if (toColl == "toColl" && !this.isCollection) {
        try {
          var needReverse = await this.toCollection();
          // 如果收藏接口返回新的交易对或者提示相反，则覆盖成新的交易对
          if (needReverse == "reverse") {
            var yenUnit2 = this.yenUnit1;
            this.yenUnit1 = this.yenUnit2;
            this.yenUnit2 = yenUnit2;
          } else {
            //返回新的交易对
            var currencyArr = needReverse.split("_");
            this.yenUnit1 = currencyArr[0];
            this.yenUnit2 = currencyArr[1];
          }
        } catch (error) {}
      }
    },
    // 取消收藏
    collectCancel(currency, web_id, web) {
      collectCancel({
        account: this.account,
        currency,
        web_id,
        web,
      }).then((res) => {
        this.$message({
          message: this.$t("tip.cancelCollectionSuc"),
          type: "success",
          center: true,
          customClass: "shotMsg",
        });
        this.getCollectList();
      });
    },

    // 选中普通盘口 快捷填入数据-手动下单-cex
    selectData(price, type, index) {
      // 数量
      let dataList = this[type].concat();
      if (type == "asksList") {
        // 卖
        var number = 0;
        for (var i = index; i < dataList.length; i++) {
          number = new Decimal(number).add(new Decimal(dataList[i].amount));
        }
      } else {
        // 买
        var number = 0;
        for (var i = 0; i <= index; i++) {
          number = new Decimal(number).add(new Decimal(dataList[i].amount));
        }
      }
      // 金额
      let total = new Decimal(price).mul(number).toString();

      this.$refs.toolBox?.selectData(price, number.toString(), total);
    },

    // 跳转-交易记录
    jump(path) {
      this.$router.push({
        path
      });
    },

    // 获取 账户余额 并重置轮询1min
    getBalance() {
      if (this.web != "opensea") {
        var currency = this.yenUnit1 + "_" + this.yenUnit2;
      } else {
        var currency = this.yenUnit1;
      }
      // 获取账户交易对余额
      getCurrencyBalance({
        web: this.web,
        account: this.account,
        currency,
      }).then((res) => {
        console.log(res)
        if (this.web != "opensea") {
          this.balance1 = res.main_balance[this.yenUnit1.toUpperCase()];
          this.balance2 = res.main_balance[this.yenUnit2.toUpperCase()];
          // 交易钱包
          this.subBalance = res.attached_balance;
        } else {
          this.balance1 = res.main_balance;
          this.balance2 = res.main_nft_assets;
          // 交易钱包
          this.subBalance = res.attached_balance;
          this.subBalance2 = res.attached_nft_assets;
        }

        // 循环获取钱包 60000
        if (banlanceTimeR) {
          clearTimeout(banlanceTimeR);
        }
        if (this.isDestroyed) {
          // 如果组件已销毁，则不再创建定时器
          return;
        }
        banlanceTimeR = setTimeout(() => {
          this.getBalance();
        }, 60000);
      });
    },
    // 获取 普通盘口 并重置轮询5s/1min
    getPankouAll(needScrollEnd) {
      var currency;
      if (this.web != "opensea") {
        currency = this.yenUnit1 + "_" + this.yenUnit2;
        // 盘口
        getPankou({
          web: this.web,
          account: this.account,
          currency,
        })
          .then((res) => {
            if (!res) {
              this.bidsList = [
                {
                  price: 0,
                  amount: 0,
                },
              ];
              this.asksList = [
                {
                  price: 0,
                  amount: 0,
                },
              ];
            } else {
              this.bidsList =
                !res.bids || !res.bids.length
                  ? [{ price: 0, amount: 0 }]
                  : res.bids;
              this.asksList =
                !res.asks || !res.asks.length
                  ? [{ price: 0, amount: 0 }]
                  : res.asks.reverse(); //res.asks;
            }
            // 卖盘滚动条移至底部
            if (needScrollEnd != "noScrollEnd") {
              this.$nextTick(() => {
                this.$refs.pankouBox?.sellScrollToBottom();
              });
            }

            // 循环获取盘口 5000
            if (pankouTimeR) {
              clearTimeout(pankouTimeR);
            }
            if (this.isDestroyed) {
              // 如果组件已销毁，则不再创建定时器
              return;
            }
            // 特殊交易所增加轮询频率、获取当前时间如果大于2025-4-1 00:00:00则失效
            var nowTime = new Date().getTime();
            var endTime = new Date("2025-04-01 00:00:00").getTime();
            if (nowTime < endTime&&(this.web=="cheezee_futures"||this.web=="bitdatech_futures")) {
              pankouTimeR = setTimeout(() => {
                // 后续更新时不需要滚动至底部
                this.getPankouAll("noScrollEnd");
              }, 1000);
            }else{
              pankouTimeR = setTimeout(() => {
                // 后续更新时不需要滚动至底部
                this.getPankouAll("noScrollEnd");
              }, 5000);
            }
          })
          .catch((err) => {
            // 如果出错 获取盘口 重置轮询60000
            if (pankouTimeR) {
              clearTimeout(pankouTimeR);
            }
            if (this.isDestroyed) {
              // 如果组件已销毁，则不再创建定时器
              return;
            }
            pankouTimeR = setTimeout(() => {
              this.getPankouAll();
            }, 60000);
          });
      } else {
        // 获取opensea盘口信息
        currency = this.yenUnit1;
        // 请求
        this.bidsList = [
          {
            price: 0,
            amount: 0,
          },
        ];
        this.asksList = [
          {
            price: 0,
            amount: 0,
          },
        ];

        if (pankouTimeR) {
          clearTimeout(pankouTimeR);
        }
      }
    },

    // 加载更多机器人状态日志
    loadRuntimeLog() {
      this.runtimeLogLoading = true;
      // 下一页
      this.runtimeLogPage++;
      var currency = this.yenUnit1 + "_" + this.yenUnit2;

      if (this.selectRuntimeLogType == "all") {
        var runtimeLogData = {
          account: this.account,
          language: this.lang,
          page: this.runtimeLogPage,
          web: "",
          currency: "",
        };
      } else {
        var runtimeLogData = {
          account: this.account,
          language: this.lang,
          page: this.runtimeLogPage,
          web: this.web,
          currency,
        };
      }

      getRuntimeLog(runtimeLogData)
        .then((res) => {
          // console.log(res)
          this.runtimeLogLoading = false;
          if (!!res && res.length > 0) {
            // && res.length >= 10
            this.runtimeLogList.push(...res);
            if (res.length < 10) {
              this.load_disabled2 = true;
            }
          } else {
            this.load_disabled2 = true;
          }

          if (runtimeLogTimeR) {
            clearTimeout(runtimeLogTimeR);
          }
          if (this.isDestroyed) {
            // 如果组件已销毁，则不再创建定时器
            return;
          }

          if (this.runtimeLogIsLoop == "loop") {
            runtimeLogTimeR = setTimeout(() => {
              this.runtimeLogLoading = false;
              this.load_disabled2 = false;
              this.getRuntimeLog();
            }, 5000);
          }
        })
        .catch((err) => {
          console.log("catch err - next", err);
          if (runtimeLogTimeR) {
            clearTimeout(runtimeLogTimeR);
          }
          if (this.isDestroyed) {
            // 如果组件已销毁，则不再创建定时器
            return;
          }
          if (this.runtimeLogIsLoop == "loop") {
            runtimeLogTimeR = setTimeout(() => {
              this.runtimeLogLoading = false;
              this.load_disabled2 = false;
              this.getRuntimeLog();
            }, 30000);
          }
        });
    },
    // 切换数据类型 当前盘口/所有盘口
    switchRuntimeLogType(selectRuntimeLogType) {
      if (selectRuntimeLogType != this.selectRuntimeLogType) {
        this.runtimeLogList = [];
        this.selectRuntimeLogType = selectRuntimeLogType;
        this.getRuntimeLog();
      }
    },
    // 切换自动更新
    changeLoop() {
      localStorage.setItem("light_is_loop", this.runtimeLogIsLoop);
      this.getRuntimeLog();
    },
    // 获取机器人状态日志 并重置轮询5s/30s
    getRuntimeLog() {
      // 初始化数据
      this.runtimeLogPage = 1;
      this.runtimeLogLoading = false;
      this.load_disabled2 = false;

      var currency = this.yenUnit1 + "_" + this.yenUnit2;

      if (this.selectRuntimeLogType == "all") {
        var runtimeLogData = {
          account: this.account,
          language: this.lang,
          page: 1,
          web: "",
          currency: "",
        };
      } else {
        var runtimeLogData = {
          account: this.account,
          language: this.lang,
          page: 1,
          web: this.web,
          currency,
        };
      }

      getRuntimeLog(runtimeLogData)
        .then((res) => {
          // console.log(res)
          this.runtimeLogList = res || [];
          if (res.length < 10) {
            this.load_disabled2 = true;
          }

          if (runtimeLogTimeR) {
            clearTimeout(runtimeLogTimeR);
          }
          if (this.isDestroyed) {
            // 如果组件已销毁，则不再创建定时器
            return;
          }

          if (this.runtimeLogIsLoop == "loop") {
            runtimeLogTimeR = setTimeout(() => {
              this.getRuntimeLog();
            }, 5000);
          }
        })
        .catch((err) => {
          console.log("catch err", err);
          if (runtimeLogTimeR) {
            clearTimeout(runtimeLogTimeR);
          }
          if (this.isDestroyed) {
            // 如果组件已销毁，则不再创建定时器
            return;
          }
          if (this.runtimeLogIsLoop == "loop") {
            runtimeLogTimeR = setTimeout(() => {
              this.getRuntimeLog();
            }, 30000);
          }
        });
    },

    // 订单/持仓 tab切换
    switchTab(tab) {
      this.tabIndex = tab;
    },
    // 获取 订单列表 并重置轮询1min
    getOrderData() {
      // 初始化数据
      this.orderPage = 1;
      if (
        this.web_type == 0||
        this.web_type == 1||
        this.web_type == 3
      ) {
        var currency = this.yenUnit1 + "_" + this.yenUnit2;
        // 订单列表
        getOrdersList({
          web: this.web,
          account: this.account,
          currency,
          page: this.orderPage,
        }).then((res) => {
          this.orderList = res||[];
          // 循环订单列表 60000
          if (orderTimeR) {
            clearTimeout(orderTimeR);
          }
          if (this.isDestroyed) {
            // 如果组件已销毁，则不再创建定时器
            return;
          }
          orderTimeR = setTimeout(() => {
            this.getOrderData();
          }, 60000);
        });
      } else {
        //opensea
        if (orderTimeR) {
          clearTimeout(orderTimeR);
        }
        this.orderList = [];
      }
    },
    // 合约-获取持仓列表
    getOrderData2(){
      // 初始化数据
      if (this.web_type == 3) {
        var currency = this.yenUnit1 + "_" + this.yenUnit2;
        getPositionOrders({
          web: this.web,
          account: this.account,
          currency,
          // page: this.orderPage2,
        }).then((res) => {
          // console.log(res)
          if(res.code==200){
            res.data.forEach((item) => {
              item.closePrice = undefined;//平仓价格
              item.closeNum = undefined;//平仓价格
            });
            this.positionList = res.data||[];
          }else{
            this.positionList = [];
          }
          
          if (this.isDestroyed) {
            // 如果组件已销毁，则不再创建定时器
            return;
          }
          // 循环订单列表 60000
          if (orderTimeR2) {
            clearTimeout(orderTimeR2);
          }
          orderTimeR2 = setTimeout(() => {
            this.getOrderData2();
          }, 60000);
        });
      } else {
        //opensea、dex
        if (orderTimeR2) {
          clearTimeout(orderTimeR2);
        }
        this.positionList = [];
      }
    },
    // 撤单
    handleCancel(item) {
      this.$confirm(this.$t("tip.cancelOrder"), this.$t("tip.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.back"),
        center: true,
        // showClose: false,
        customClass: "deleteOk",
      })
        .then(() => {
          var currency;
          if (this.web != "opensea") {
            currency = this.yenUnit1 + "_" + this.yenUnit2;
          } else {
            currency = this.yenUnit1;
          }
          tradeCancel({
            web: this.web,
            account: this.account,
            currency,
            order_id: item.id,
            type: item.side,
          }).then((res) => {
            this.$message({
              message: this.$t("tip.cancelSuc"),
              type: "success",
              center: true,
              offset: 100,
              customClass: "shotMsg",
            });
            // 获取 账户余额、普通盘口、订单列表 并重置轮询
            this.updateData();
          });
        })
        .catch(() => {
          // 取消
        });
    },
    // 平仓 市价全平
    handleClosePosition(item,futuresType){
      if(futuresType=='limit'&&!item.closePrice){
        this.$message({
          message: this.$t("tip.enterClosePrice"),
          type: "error",
          center: true,
          customClass: "shotMsg",
        });
      }else if(futuresType=='limit'&&!item.closeNum){
        this.$message({
          message: this.$t("tip.enterCloseNum"),
          type: "error",
          center: true,
          customClass: "shotMsg",
        });
      }else{
        this.$confirm(this.$t("tip."+(futuresType=='limit'?'confirmCloseposition':'confirmCloseAllPosition')), this.$t("tip.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.back"),
          center: true,
          // showClose: false,
          customClass: "deleteOk",
        }).then(()=>{
          // 合约手动下单
          tradeCoin({
            web: this.web,
            account: this.account,
            currency: item.currency,
            num: futuresType=='limit'?item.closeNum:item.balance,
            price: futuresType=='limit'?item.closePrice:1,
            mode: 1,//下单模式默认普通模式
            trade_type:item.direction == 'short' ? 4 : 3,
            approve_type: '1',
            futures_type: futuresType,//limit限价 market市价
          }).then(res => {
            // 成功
            this.$message({
                message: this.$t('tip.operationSuc'),
                type: 'success',
                center: true,
                customClass: 'shotMsg',
            });
            // 获取新数据 重置轮询 资金、订单、盘口相关
            this.updateData();
          })
        })
      }
    },

    // 机器人-子组件交易所
    openPop(data, robotType) {
      // 子组件交易所数据展示
      this.dialogWeb = data.web;

      this.sonPopRobotType = robotType;
      this.popExchange = true;
      if (data.num) {
        this.sonFormNum = data.num;
      }
    },
    // 交易所
    closeExchange() {
      this.popExchange = false;
      // 子组件判定初始化
      if (this.sonPopRobotType || this.isAddToken) {
        this.sonPopRobotType = "";
        this.isAddToken = false;
      }
    },
    // 添加token合约选择交易所
    openPopAddToken() {
      // 交易所数据展示
      this.dialogWeb = this.addTokenWeb;

      this.isAddToken = true;
      this.popExchange = true;
    },
    openPopExchange() {
      this.dialogWeb = this.web;

      this.popExchange = true;
    },
    handleSelect(data) {
      if (this.sonPopRobotType) {
        this.$refs.robotBox.handleSelect(
          data,
          this.sonFormNum,
          this.sonPopRobotType
        );
      } else if (this.isAddToken) {
        //添加token合约 dex工具
        this.addTokenExchange = data.text;
        this.addTokenWeb = data.web;
      } else {
        this.popexchange = data.text;
        this.popweb = data.web;
        this.popweb_id = data.web_id;
        this.popweb_type = data.web_type;
      }
      this.popExchange = false;
    },

    // 联系客服 隐藏联系方式
    // contact() {
    //   getService({
    //     account: this.account,
    //     source: 1, //0-微信，1-telegram，2-Facebook，3-discord
    //   }).then((res) => {
    //     if (!!res) {
    //       if (res.length == 1) {
    //         // 如果只有一个联系方式 直接打开
    //         window.location.href = res[0].wechat;
    //       } else if (res.length > 1) {
    //         // 如果有多个联系方式 打开弹窗展示列表
    //         this.contactList = res;
    //         this.showContact = true;
    //       } else {
    //         // 没有联系方式
    //       }
    //     }
    //   });
    // },
    // 跳外部链接
    jumpOut(path) {
      window.location.href = path;
    },
  },
  watch: {
    lang() {
      this.getRuntimeLog();
    },
    device2() {
      this.$nextTick(() => {
        this.$refs.pankouBox?.sellScrollToBottom();
      });
    },
  },
  beforeDestroy() {
    this.isDestroyed = true;
    if (banlanceTimeR) {
      clearTimeout(banlanceTimeR);
      banlanceTimeR = "";
    }
    if (pankouTimeR) {
      clearTimeout(pankouTimeR);
      pankouTimeR = "";
    }
    if (orderTimeR) {
      clearTimeout(orderTimeR);
      orderTimeR = "";
    }
    if (orderTimeR2) {
      clearTimeout(orderTimeR2);
      orderTimeR2 = "";
    }
    // 获取机器人状态日志
    if (runtimeLogTimeR) {
      clearTimeout(runtimeLogTimeR);
      runtimeLogTimeR = "";
    }
  },
};
</script>